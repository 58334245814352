import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import DITRDCBackground from "src/images/DITRDC_Artwork_background.png";

const config = [
  {
    name: "Home First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: { pageName: "home", targetModalId: "intro" },
  },
  {
    name: "Hero Image",
    moduleComponentName: "HeroImage",
    moduleProps: { style: { height: "45vh" } },
    rowProps: { noGutters: true },
  },
  {
    name: "Hero Image Links",
    children: [
      {
        name: "image link 1",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `View the preliminary flight paths for WSI using the Aircraft Overflight Noise Tool.`,
          fileName: "image-link-1",
          url: { path: "https://wsiflightpaths.aerlabs.com", isExternal: true },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "#071e3e",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
      {
        name: "image link 2",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `
          View the EIS to learn about the environmental, health, social and economic impacts of the flight paths
                `,
          fileName: "image-link-2",
          url: { path: "/download-the-eis" },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "#071e3e",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
      {
        name: "image link 3",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `Contact us if you have questions.`,
          fileName: "image-link-3",
          url: { path: "/contact-us" },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "#071e3e",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
    ],
    rowProps: {
      noGutters: true,
    },
    wrapWithContainer: true,
  },
  {
    name: "About WSIA",
    children: [
      {
        name: "About us heading",
        columnProps: { xs: 12, className: "mb-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          //alignCenter: true,
          heading: {
            priority: 1,
            style: { color: `#78d1f5`, fontSize: 30 },
            text: `Western Sydney International (Nancy-Bird Walton) Airport flight path design for single runway operations`,
          },
        },
      },
      {
        name: "About us text",
        id: "About us text",
        moduleComponentName: "SectionContent",
        columnProps: {
          xs: 12,
          className: "mb-3",
        },
        rowProps: { noGutters: true },
        moduleProps: {
          elementId: "home-page-about",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      // Center non-absolutely positioned content and make the row full height to overlay the video.
      className: "pt-3 pt-md-5 bg-primary",
      style: {
        backgroundImage: `url(${DITRDCBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        paddingBottom: "48px",
      },
      //   "min-vh-100 py-4 py-md-0 d-flex align-content-center align-items-stretch justify-content-center",
      // style: { backgroundImage: `url(${heroMobileImage})`, objectFit: "cover" },
    },
    wrapWithContainer: true,
  },
];

const LandingPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default LandingPage;
